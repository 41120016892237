"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _textArea = require("./text-area");
Object.keys(_textArea).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _textArea[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _textArea[key];
    }
  });
});