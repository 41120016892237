"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MainNav", {
  enumerable: true,
  get: function get() {
    return _mainNav.MainNav;
  }
});
var _mainNav = require("./main-nav");